import { VoucherIcon } from '@setel/web-ui';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  code?: string;
  className?: string;
}

const defaultCode = 'c-mp2025';

export const Ccode = ({ code, className }: Props) => {
  let { t } = useTranslation();

  return (
    <div
      className={cn(
        'w-full flex flex-row justify-center items-center gap-2 font-normal text-xs text-gray-dark rounded bg-[rgba(0,176,255,0.1)] py-2 px-3',
        className
      )}
    >
      <div>
        <VoucherIcon className="w-[18px] text-[#00B0FF]" />
      </div>

      <div className="text-left">
        <Trans
          i18nKey="footerSMSForm.cCodeText"
          components={{
            Code: (
              <span className="font-semibold uppercase text-[#008DCC] whitespace-nowrap tracking-widest">
                {code ?? defaultCode}
              </span>
            ),
          }}
        ></Trans>
      </div>
    </div>
  );
};
